/* eslint-disable no-case-declarations */
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Inject, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { HtmlEditor, Image, Count, Link, Toolbar as rteToolbar, QuickToolbar, RichTextEditorComponent } from '@syncfusion/ej2-react-richtexteditor';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent, ListBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { NumericTextBoxComponent, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { useEffect, useState } from 'react';
import { confirm } from "react-confirm-box";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getAuthToken } from '../../helpers/authentication';
import { CommandTemplate, ConfirmDialogStyles, SearchGridToolbar, SimpleToolbarSettings } from '../../helpers/Global';
import { fieldRequired } from '../../helpers/validation';


export default function BestSellers(props) {

  const [editData, setEditData] = useState();
  const [editId, setEditId] = useState(0);

  const [site, setSite] = useState(0);
  const [productId, setProductId] = useState(0);
  const [displayOrder, setDisplayOrder] = useState(0);
  const [dateAdded, setDateAdded] = useState(new Date());
  const [addedBy, setAddedBy] = useState(0);

  const [editModal, setEditModal] = useState(false);

  const toggle = () => setEditModal(!editModal);


  const commandClick = async (args) => {
    switch (args.commandColumn.type) {
      case 'Delete':
        if (await confirm("Are you sure you want to delete this item?", ConfirmDialogStyles)) {
          deleteItem(args.rowData.id);
        }
        break;
      default: //edit
        editItem(args.rowData.id);
        break;
    }
  }

  const loadData = async () => {
    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('/api/bestsellers', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setEditData(data);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }
  }

  const addItem = () => { editItem(0); }

  const editItem = (id) => {
    if (id > 0) {
      const data = editData.find((item) => { return item.id === id });
      setEditId(data.id);
      //setId(data.id);
      setSite(data.site);
      setProductId(data.productId);
      setDisplayOrder(data.displayOrder);
      setDateAdded(data.dateAdded);
      setAddedBy(data.addedBy);

    }
    else {
      //clear state fields
      setEditId(0);

      setSite(0);
      setProductId(0);
      setDisplayOrder(0);
      setDateAdded(new Date());
      setAddedBy(0);

    }
    setEditModal(true);
  };

  const validateForm = () => {
    var valid = true;
    valid &= fieldRequired(site, 'tbSiteError', '* required');
    valid &= fieldRequired(productId, 'tbProductIdError', '* required');
    valid &= fieldRequired(displayOrder, 'tbDisplayOrderError', '* required');

    return valid;
  }

  const saveItem = async (event) => {
    event.stopPropagation();

    if (validateForm()) {
      const data = { id: editId, site: site, productId: productId, displayOrder: displayOrder, dateAdded: dateAdded, addedBy: addedBy, };

      var bearer = 'Bearer ' + getAuthToken();

      try {
        const response = await fetch('/api/bestsellers', {
          method: editId === 0 ? 'POST' : 'PUT',
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
        if (response.ok) {
          await response.json();
          loadData();
          ToastUtility.show({
            title: 'Best Sellers', content: 'The best sellers was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
          });
        }
        else {
          console.log(response.status + ": " + response.statusText);
          if (response.status === 401)
            window.location.href = "/login";
        }

      } catch (e) {
        console.error(e);
        ToastUtility.show({
          title: 'Best Sellers', content: 'There was an error saving the best sellers!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
        });
      }
      toggle();
    }
  }

  const deleteItem = async (dataId) => {

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('/api/bestsellers/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        loadData();
        ToastUtility.show({
          title: 'Best Sellers', content: 'The best sellers was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Best Sellers', content: 'There was an error deleting the best sellers!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }


  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-6 "><h1>Best Sellers</h1></div>
        <div className="col-md-6 text-end align-self-center">
          <Button color="primary" size="sm" onClick={addItem}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
        </div>
      </div>

      <GridComponent dataSource={editData} allowSorting={true} toolbar={SearchGridToolbar} commandClick={commandClick} >
        <ColumnsDirective>
          <ColumnDirective field='ProductId' width='100' headerText="ProductId" />
          <ColumnDirective headerText='Actions' width='25' commands={CommandTemplate} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      </GridComponent>

      <Modal isOpen={editModal} toggle={toggle} scrollable size="lg" backdrop="static">
        <ModalHeader toggle={toggle}>Edit Best Sellers</ModalHeader>
        <ModalBody>
          <div className='mb-3'>
            <NumericTextBoxComponent id='tbSite' name='tbSite' placeholder='Site' floatLabelType='Auto' showClearButton={true} value={site} onChange={e => setSite(e.value)} />
            <div id='tbSiteError' className='error-message' />
          </div>

          <div className='mb-3'>
            <DropDownListComponent id='ddProductId' name='ddProductId' placeholder='Select Product Id' floatLabelType='Auto' showClearButton={true} value={productId} onChange={e => setProductId(e.value)} />
            <div id='ddProductIdError' className='error-message' />
          </div>

          <div className='mb-3'>
            <NumericTextBoxComponent id='tbDisplayOrder' name='tbDisplayOrder' placeholder='Display Order' floatLabelType='Auto' showClearButton={true} value={displayOrder} onChange={e => setDisplayOrder(e.value)} />
            <div id='tbDisplayOrderError' className='error-message' />
          </div>


        </ModalBody>
        <ModalFooter>
          <Button color="dark" size="sm" onClick={toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
          <Button color="success" size="sm" onClick={saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
        </ModalFooter>
      </Modal>

    </>
  );
}

