import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "../helpers/authentication";
import { Context } from "../helpers/Context";
import { getCurrentDomain } from "../helpers/Lookup";
import CartItem from "./CartItem";

const Cart = () => {
  const [couponCode, setCouponCode] = useState('');
  const navigate = useNavigate();
  const { cartItems, setShowCart, cartSubTotal, cart, applyCoupon, couponError, appliedCoupon } = useContext(Context);


  const handlePayment = async () => {
    var bearer = 'Bearer ' + getAuthToken();
    var data = {
      Id: cart.id,
      CartTotal: cartSubTotal,
      FirstName: "",
      LastName: "",
      Email: "",
      ReturnUrl: getCurrentDomain() + "/return-payment",
      CancelUrl: getCurrentDomain() + "/cancel-payment",
      NotifyUrl: getCurrentDomain() + "/notify-payment",
    }
    try {
      const response = await fetch('/api/payfast/processpayment', {
        method: 'POST',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        console.log("done")
      } else {
        console.log("oh no")
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="cart-panel">
      <div
        className="opac-layer"
        onClick={() => setShowCart(false)}
      ></div>
      <div className="cart-content">
        <div className="cart-header">
          <span className="heading">Shopping Cart</span>
          <span className="close-btn" onClick={() => setShowCart(false)} >
            <i className="fa-solid fa-xmark"></i>
            <span className="text">close</span>
          </span>
        </div>

        {!cartItems?.length && (
          <div className="empty-cart">
            <i className="fa-regular fa-cart-shopping"></i>
            <span>No products in the cart.</span>
            <button className="return-cta" onClick={() => { navigate("/products"); setShowCart(false); }}>
              RETURN TO SHOP
            </button>
          </div>
        )}

        {!!cartItems?.length && (
          <>
            <div className="scrollable-cart">
              <CartItem />
            </div>
            <div className="cart-footer">
              <div className="subtotal">
                <span className="text">Subtotal:</span>
                <span className="text total">
                  {cartSubTotal.toCurrencyString()}
                </span>
              </div>
              
              <div className="button">
               
                <button className="checkout-cta" onClick={() => { navigate("/checkout"); setShowCart(false); }} >Go To Checkout</button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Cart;