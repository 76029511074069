import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { getAuthToken } from '../../helpers/authentication';
import  StockUpdates  from './Admin Dashboard/StockUpdates';

export class AdminLanding extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      editData: [], loading: true,
    };
  }

  componentDidMount() {
    document.title = "Site Administration :: TriggerCraft";
    this.loadData();
  }

  render() {
    //let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : BannerLocations.renderDataTable(this.state.editData, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);
      //console.log(this.state)
    return (
      <>
        <div className="container">
          <Row>
            <Col xs={12}>
              <h1>Site Administration</h1>
            </Col>
            <Col xs={12} >
              <StockUpdates />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h5 className="border-bottom">Dashboards</h5>
              <Link to='/admin' className='btn btn-success bg-gradient btn-admin'>Admin Dashboard</Link>
              <Link to='/admin/order-dashboard' className='btn btn-success bg-gradient btn-admin position-relative'>
                Order Dashboard
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                {!this.state.loading && this.state.editData.length}
                  <span className="visually-hidden">New Orders</span>
                </span>
              </Link>

              <h5 className="border-bottom mt-4">Products</h5>
              <Link to='/admin/products' className='btn btn-success bg-gradient btn-admin'>Products</Link>
              {/*<Link to='/admin/product-types' className='btn btn-success bg-gradient btn-admin'>Product Types</Link>*/}
              {/*<Link to='/admin/product-ranges' className='btn btn-success bg-gradient btn-admin'>Product Ranges</Link>*/}
              <Link to='/admin/product-categories' className='btn btn-success bg-gradient btn-admin'>Product Categories</Link>
              <Link to='/admin/product-sub-categories' className='btn btn-success bg-gradient btn-admin'>Product Subcategories</Link>
              <Link to='/admin/product-brands' className='btn btn-success bg-gradient btn-admin'>Product Brands</Link>
              <Link to='/admin/best-sellers' className='btn btn-success bg-gradient btn-admin'>Best Sellers</Link>
              <Link to='/admin/promo-codes' className='btn btn-success bg-gradient btn-admin'>Promo Codes</Link>
              <Link to='/admin/promotions' className='btn btn-success bg-gradient btn-admin'>Promotions</Link>

              <h5 className="border-bottom mt-4">Contacts</h5>
              <Link to='/admin/contact-messages' className='btn btn-success bg-gradient btn-admin'>Contact Messages</Link>
              <Link to='/admin/contact-reasons' className='btn btn-success bg-gradient btn-admin'>Contact Reasons</Link>

              <h5 className="border-bottom mt-4">Pages &amp; Banners</h5>
              <Link to='/admin/page-content' className='btn btn-success bg-gradient btn-admin'>Page Content</Link>
              <Link to='/admin/faqs' className='btn btn-success bg-gradient btn-admin'>FAQs</Link>
              <Link to='/admin/banners' className='btn btn-success bg-gradient btn-admin'>TriggerCraft Banners</Link>
              <Link to='/admin/razer-banners' className='btn btn-success bg-gradient btn-admin'>Razer Banners</Link>

              {/*<Link to='/admin/banner-sections' className='btn btn-success bg-gradient btn-admin'>Banner Sections</Link>*/}
              {/*<Link to='/admin/banner-locations' className='btn btn-success bg-gradient btn-admin'>Banner Locations</Link>*/}
              

              <h5 className="border-bottom mt-4">Users</h5>
              <Link to='/admin/users' className='btn btn-success bg-gradient btn-admin'>Site Users</Link>

              <h5 className="border-bottom mt-4">Admin Orders</h5>
              <Link to='/admin/admin-orders' className='btn btn-success bg-gradient btn-admin'>Admin Order Creation</Link>
              
              <h5 className="border-bottom mt-4">Audit</h5>
              <Link to='/admin/audit-log' className='btn btn-success bg-gradient btn-admin'>Audit Log </Link>
            </Col>
          </Row>
        </div>
      </>

    );
  }
    async loadData() {

        var bearer = 'Bearer ' + getAuthToken();
        try {
            const response = await fetch('api/cart', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                let data = await response.json();
                data = data.filter(item => item.status !== 0 && item.status !== 3)
                this.setState({ editData: data, loading: false });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
        }
    }
}

