import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Context } from "../helpers/Context";
import { getAuthToken, getUserDetails } from '../helpers/authentication';
import { Button } from 'reactstrap';
import FixedMessenger from './FixedMessenger';
import { fieldRequired } from '../helpers/validation';

const DeliveryDetails = () => {
  const { userAddress, getUserAddress } = useContext(Context);
  const { id } = useParams();
  const navigate = useNavigate();
  const userId = getUserDetails().id
  const [formData, setFormData] = useState({
    Address: '',
    Apartment: '',
    City: '',
    Company: '',
    Country: '',
    DateAdded: new Date(),
    DateModified: new Date(),
    Id: 0,
    MobileNumber: '',
    OldId: 0,
    PostalCode: '',
    Status: 1,
    Suburb: '',
    Province: '',
    UserId: userId,
  });

  const provinces = [
    { id: 1, abbreviation: 'EC', name: 'Eastern Cape' },
    { id: 2, abbreviation: 'FS', name: 'Free State' },
    { id: 3, abbreviation: 'GP', name: 'Gauteng' },
    { id: 4, abbreviation: 'KZN', name: 'KwaZulu-Natal' },
    { id: 5, abbreviation: 'LP', name: 'Limpopo' },
    { id: 6, abbreviation: 'MP', name: 'Mpumalanga' },
    { id: 7, abbreviation: 'NC', name: 'Northern Cape' },
    { id: 8, abbreviation: 'NW', name: 'North West' },
    { id: 9, abbreviation: 'WC', name: 'Western Cape' },
  ];

  const nearSouthAfricaCountries = [
    { id: 1, abbreviation: 'BW', name: 'Botswana' },
    { id: 2, abbreviation: 'LS', name: 'Lesotho' },
    { id: 3, abbreviation: 'MZ', name: 'Mozambique' },
    { id: 4, abbreviation: 'NA', name: 'Namibia' },
    { id: 5, abbreviation: 'SZ', name: 'Eswatini' },
    { id: 6, abbreviation: 'ZW', name: 'Zimbabwe' },
    { id: 7, abbreviation: 'ZA', name: 'South Africa' },
  ];


  useEffect(() => {
    document.title = "Delivery Details :: TriggerCraft";
    getUserAddress(id);
  }, [])

  useEffect(() => {

    if (userAddress) {
      setFormData({
        Address: userAddress.address || '',
        Apartment: userAddress.apartment || '',
        City: userAddress.city || '',
        Company: userAddress.company || '',
        Country: userAddress.country || '',
        Id: userAddress.id || 0,
        MobileNumber: userAddress.mobileNumber || '',
        PostalCode: userAddress.postalCode || '',
        Suburb: userAddress.suburb || '',
        Province: userAddress.province || '',

        DateAdded: userAddress.dateAdded || new Date(),
        DateModified: new Date(),
        OldId: userAddress.oldId || 0,
        Status: userAddress.status || 1,
        UserId: userAddress.userId || userId,
      });
    }
  }, [userAddress])

  const handleSubmit = async (e) => {
    //console.log(formData)
    var valid = true;
    valid &= fieldRequired(formData.Address, 'tbAddressError', '* required');
    valid &= fieldRequired(formData.City, 'tbCityError', '* required');
    valid &= fieldRequired(formData.Suburb, 'tbSuburbError', '* required');
    valid &= fieldRequired(formData.Province, 'ddProvinceError', '* required');
    valid &= fieldRequired(formData.PostalCode, 'tbPostalCodeError', '* required');
    valid &= fieldRequired(formData.Country, 'ddCountryError', '* required');
    valid &= fieldRequired(formData.MobileNumber, 'tbMobileNumberError', '* required');
    //e.preventDefault();
    var bearer = 'Bearer ' + getAuthToken();
    if (valid) {
      const response = await fetch('/api/useraddress', {
        method: formData.Id === 0 ? 'POST' : 'PUT',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        ToastUtility.show({
          title: 'User Address', content: 'Address saved successfully', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
        navigate(-1);
      } else {
        ToastUtility.show({
          title: 'User Address', content: 'There was an error saving address', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
        });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let realValue = e.value
    //console.log(name, e.value, value)
    setFormData((prevData) => ({
      ...prevData,
      [name]: value ? realValue : e.value,
    }));
  };

  const maxLength50 = { maxlength: "50" };
  const maxLength100 = { maxlength: "100" };
  const maxLength250 = { maxlength: "250" };
  const maxLength4 = { maxlength: "4" };

  //console.log(formData)
  return (
    <>
      <nav className="mb-3 crumbs">
        <Link to="/" className="breadcrumb-active"><small>Home</small>
        </Link>
        <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
        <small className="breadcrumb-not-active">New Address</small>
      </nav>

      <div className="col-md-8 mx-auto">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h1 className="">Delivery Details</h1>
          <div>
            <Button color='dark' size='sm' outline onClick={() => navigate('/customer-delivery-addresses')}>
              <i className="fas fa-chevron-left mr-2"></i>&nbsp; Go Back
            </Button>
          </div>
        </div>
        <div className="row">
          <div className='mb-3 col-md-6'>
            <TextBoxComponent id='tbAddress' name='Address' placeholder='Address' type='text' htmlAttributes={maxLength250} floatLabelType='Always' showClearButton={true} value={formData.Address} onChange={handleChange} /> <div id='tbAddressError' className='error-message' />
          </div>
          <div className='mb-3 col-md-6'>
            <TextBoxComponent id='tbCompany' name='Company' placeholder='Company (optional)' type='text' htmlAttributes={maxLength250} floatLabelType='Always' showClearButton={true} value={formData.Company} onChange={handleChange} /> <div id='tbCompanyError' className='error-message' />
          </div>
        </div>
        <div className="row">
          <div className='mb-3 col-md-6'>
            <TextBoxComponent id='tbApartment' name='Apartment' placeholder='Apartment (optional)' type='text' htmlAttributes={maxLength100} floatLabelType='Always' showClearButton={true} value={formData.Apartment} onChange={handleChange} /> <div id='tbApartmentError' className='error-message' />
          </div>

          <div className='mb-3 col-md-6'>
            <TextBoxComponent id='tbCity' name='City' placeholder='City' type='text' htmlAttributes={maxLength100} floatLabelType='Always' showClearButton={true} value={formData.City} onChange={handleChange} /> <div id='tbCityError' className='error-message' />
          </div>
        </div>

        <div className="row">
          <div className='mb-3 col-md-6'>
            <TextBoxComponent id='tbSuburb' name='Suburb' placeholder='Suburb' type='text' htmlAttributes={maxLength100} floatLabelType='Always' showClearButton={true} value={formData.Suburb} onChange={handleChange} /> <div id='tbSuburbError' className='error-message' />
          </div>

          <div className='mb-3 col-md-6'>
            <DropDownListComponent id='ddProvince' name='Province' placeholder='Province' dataSource={provinces} fields={{ text: 'name', value: 'abbreviation' }} floatLabelType='Always' value={formData.Province} change={e => setFormData({ ...formData, Province: e.value })} />
            <div id='ddProvinceError' className='error-message' />
          </div>
        </div>

        <div className="row mb-3">
          <div className='mb-3 col-md-4'>
            <TextBoxComponent id='tbPostalCode' name='PostalCode' placeholder='Postal Code' type='text' htmlAttributes={maxLength4} floatLabelType='Always' showClearButton={true} value={formData.PostalCode} onChange={handleChange} /> <div id='tbPostalCodeError' className='error-message' />
          </div>

          <div className='mb-3 col-md-4'>
            <DropDownListComponent id='ddCountry' name='Country' placeholder='Country' dataSource={nearSouthAfricaCountries} fields={{ text: 'name', value: 'abbreviation' }} floatLabelType='Always' value={formData.Country} change={e => setFormData({ ...formData, Country: e.value })} />
            <div id='ddCountryError' className='error-message' />
          </div>

          <div className='mb-3 col-md-4'>
            <TextBoxComponent id='tbMobileNumber' name='MobileNumber' placeholder='Mobile Number' type='text' htmlAttributes={maxLength50} floatLabelType='Always' showClearButton={true} value={formData.MobileNumber} onChange={handleChange} /> <div id='tbMobileNumberError' className='error-message' />
          </div>
        </div>

        <Button color='dark' size='sm' outline onClick={handleSubmit} >Save Changes</Button>
        <FixedMessenger />
      </div>
    </>
  );
};
export default DeliveryDetails;