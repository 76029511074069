import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Context } from "../helpers/Context";
import FixedMessenger from "./FixedMessenger";
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { fieldRequired } from "../helpers/validation";
import { getDefaultAddressId, setDefaultAddressId } from "../helpers/Lookup";
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { getAuthToken, getUserDetails } from "../helpers/authentication";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const OrderCompletion = () => {
  const navigate = useNavigate();
  const { getUserAddress, userAddresses, processPayment, cart } = useContext(Context);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(true);

  const [addressId, setAddressId] = useState(getDefaultAddressId());

  const [company, setCompany] = useState('');
  const [instructions, setInstructions] = useState('');
  const [province, setProvince] = useState('');
  const [address, setAddress] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [apartment, setApartment] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [suburb, setSuburb] = useState('');
  const [mobileNumber, setMobileNumber] = useState(getUserDetails().mobile);


  const [editModal, setEditModal] = useState(false);

  const toggle = () => setEditModal(!editModal);


  const provinces = [
    { id: 0, abbreviation: '', name: '-SELECT-' },
    { id: 1, abbreviation: 'EC', name: 'Eastern Cape' },
    { id: 2, abbreviation: 'FS', name: 'Free State' },
    { id: 3, abbreviation: 'GP', name: 'Gauteng' },
    { id: 4, abbreviation: 'KZN', name: 'KwaZulu-Natal' },
    { id: 5, abbreviation: 'LP', name: 'Limpopo' },
    { id: 6, abbreviation: 'MP', name: 'Mpumalanga' },
    { id: 7, abbreviation: 'NC', name: 'Northern Cape' },
    { id: 8, abbreviation: 'NW', name: 'North West' },
    { id: 9, abbreviation: 'WC', name: 'Western Cape' },
  ];

  const handleAddressChange = (address) => {
    setAddressId(address.id);
    setDefaultAddressId(address.id);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    if (addressId > 0) {
      if (getAuthToken()) {
        console.log(cart);
        let cartId = cart.cartId;
        //          JSON.parse(localStorage.getItem('cart')).cartId;
        console.log(cartId);
        await processPayment(cartId, addressId, instructions);
        setIsButtonDisabled(false);
      } else {
        alert("Please login or register to complete order");
        setIsButtonDisabled(false);
      }
    }
    else {
      alert("Please add a delivery address!");
      setIsButtonDisabled(false);
    }
  };

  useEffect(() => {
    document.title = "Order Completion :: TriggerCraft";
    getUserAddress();
    setLoading(false);
  }, [])
  
  const renderAddress = (address) => {
    let result = "";
    if (!address?.company.isNullOrWhitespace())
      result += address.company + "<br />";
    if (!address?.apartment.isNullOrWhitespace())
      result += address.apartment + "<br />";
    if (!address?.address.isNullOrWhitespace())
      result += address.address + "<br />";
    if (!address?.suburb.isNullOrWhitespace())
      result += address.suburb + "<br />";
    return result;
  }

  const validateForm = () => {
    var valid = true;
    valid &= fieldRequired(address, 'tbAddressError', '* required');
    valid &= fieldRequired(city, 'tbCityError', '* required');
    valid &= fieldRequired(suburb, 'tbSuburbError', '* required');
    valid &= fieldRequired(province, 'ddProvinceError', '* required');
    valid &= fieldRequired(postalCode, 'tbPostalCodeError', '* required');
    //valid &= fieldRequired(country, 'ddCountryError', '* required');
    //valid &= fieldRequired(mobileNumber, 'tbMobileNumberError', '* required');
    return valid;
  }


  const saveItem = async (event) => {
    event.stopPropagation();

    if (validateForm()) {
      const data = { id: 0, userId: getUserDetails().id, company: company, province: province, address: address, postalCode: postalCode, apartment: apartment, country: country, city: city, suburb: suburb, mobileNumber: mobileNumber, };

      var bearer = 'Bearer ' + getAuthToken();

      try {
        const response = await fetch('/api/useraddress', {
          method: 'POST',
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
        if (response.ok) {
          const data = await response.json();
          console.log("after save: ", data)
          //loadData();
          const saveId = parseInt(data, 10);
          setAddressId(saveId);
          setDefaultAddressId(saveId);
          getUserAddress();
          ToastUtility.show({
            title: 'Address', content: 'Your address was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
          });
        }
        else {
          console.log(response.status + ": " + response.statusText);
          if (response.status === 401)
            window.location.href = "/login";
        }

      } catch (e) {
        console.error(e);
        ToastUtility.show({
          title: 'Address', content: 'There was an error saving  your address!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
        });
      }
      toggle();
    }
  }


  if (loading) {
    return (
      <>
        <div className="text-center mt-3"><i className='fas fa-spinner fa-spin fa-2x'></i><br />Loading...</div>
      </>
    )
  }

  return (
    <>
      <nav className="mb-3 crumbs">
        <Link to="/" className="breadcrumb-active"><small>Home</small></Link>
        <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
        <Link to="/checkout" className="breadcrumb-active"><small>Checkout</small></Link>
        <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
        <small className="breadcrumb-not-active">Order Completion</small>
      </nav>
      <h1 className="mb-2">Delivery Address</h1>
      <div className="row">
        <div className="col-md-6">
          

          {userAddresses?.map((item, index) => {
            const inputId = `address-${index}`; // Generate a unique ID for each radio button

            return (
              <div key={index} className="mb-2 form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  id={inputId} // Set the unique ID for the input
                  name="address"
                  value={item.id}
                  checked={item.id === addressId}
                  onChange={() => handleAddressChange(item)}
                />
                <label className="form-check-label ms-2" htmlFor={inputId} dangerouslySetInnerHTML={{ __html: renderAddress(item) }} />
              </div>
            );
          })}


          <hr />
          <button className="checkout-btn small" onClick={() => toggle()}>
            <i className="fas fa-map-marker-alt me-2"></i>Add Address
          </button>

        </div>
        <div className="col-md-6">

          <div className="form-group mb-3">
            <TextBoxComponent id='tbInstructions' name='tbCompany' placeholder='Special Instructions' type='text' multiline={true} htmlattributes={{ maxLength: '2500', rows: '4' }} floatLabelType='Always' showClearButton={true} value={instructions} onChange={e => setInstructions(e.value)} /> 
          </div>

          <div className="row ">
            <div className="col-md-6">
              <div className="complete">
                <Button color='dark' size='sm' outline onClick={() => navigate(-1)}>
                  <i className="fas fa-chevron-left mr-2"></i>&nbsp; Back to cart
                </Button>
              </div>  
            </div>

            <div className="col-md-6">
              <div className="complete">
                <button className="checkout-btn" disabled={isButtonDisabled} onClick={handleSubmit}>
                  Complete Order &nbsp; {isButtonDisabled ? <i className='fas fa-spinner fa-spin ms-2'></i> : <i className="fas fa-chevron-right ml-2"></i>}
                </button>
              
            </div>
          </div>
          </div>
          

        </div>
        
      </div>


      <FixedMessenger />

      <Modal isOpen={editModal} toggle={toggle} scrollable size="lg" backdrop="static">
        <ModalHeader toggle={toggle}>Add Address</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className='mb-3 col-md-6'>
              <TextBoxComponent id='tbAddress' name='tbAddress' placeholder='Address' type='text' htmlattributes={{ maxLength: '250' }} floatLabelType='Auto' showClearButton={true} value={address} onChange={e => setAddress(e.value)} />
              <div id='tbAddressError' className='error-message' />
            </div>

            <div className='mb-3 col-md-6'>
              <TextBoxComponent id='tbApartment' name='tbApartment' placeholder='Apartment (Optional)' type='text' htmlattributes={{ maxLength: '100' }} floatLabelType='Auto' showClearButton={true} value={apartment} onChange={e => setApartment(e.value)} />

            </div>
            <div className='mb-3 col-md-6'>
              <TextBoxComponent id='tbCompany' name='tbCompany' placeholder='Company Name (Optional)' type='text' htmlattributes={{ maxLength: '250' }} floatLabelType='Auto' showClearButton={true} value={company} onChange={e => setCompany(e.value)} />
            </div>
            <div className='mb-3 col-md-6'>
              <TextBoxComponent id='tbSuburb' name='tbSuburb' placeholder='Suburb' type='text' htmlattributes={{ maxLength: '100' }} floatLabelType='Auto' showClearButton={true} value={suburb} onChange={e => setSuburb(e.value)} />
              <div id='tbSuburbError' className='error-message' />
            </div>
            <div className='mb-3 col-md-6'>
              <TextBoxComponent id='tbCity' name='tbCity' placeholder='City' type='text' htmlattributes={{ maxLength: '100' }} floatLabelType='Auto' showClearButton={true} value={city} onChange={e => setCity(e.value)} />
              <div id='tbCityError' className='error-message' />
            </div>
            <div className='mb-3 col-md-6'>
              <DropDownListComponent id='ddProvince' name='province' placeholder='Province' dataSource={provinces} fields={{ text: 'name', value: 'abbreviation' }} floatLabelType='Always' value={province} change={e => setProvince(e.value)} />
              <div id='ddProvinceError' className='error-message' />
            </div>

            <div className='mb-3 col-md-6'>
              <TextBoxComponent id='tbPostalCode' name='tbPostalCode' placeholder='Postal Code' type='text' htmlattributes={{ maxLength: '4' }} floatLabelType='Auto' showClearButton={true} value={postalCode} onChange={e => setPostalCode(e.value)} />
              <div id='tbPostalCodeError' className='error-message' />
            </div>
            <div className='mb-3 col-md-6'>
              <TextBoxComponent id='tbMobileNumber' name='tbMobileNumber' placeholder='Mobile Number' type='text' htmlattributes={{ maxLength: '25' }} floatLabelType='Auto' showClearButton={true} value={mobileNumber} onChange={e => setMobileNumber(e.value)} />
            </div>

          </div>


          <div className='mb-3 d-none'>
            <TextBoxComponent id='tbCountry' name='tbCountry' placeholder='Country' type='text' maxLength='100' floatLabelType='Auto' showClearButton={true} value={country} onChange={e => setCountry(e.value)} />
            <div id='tbCountryError' className='error-message' />
          </div>

        </ModalBody>
        <ModalFooter>
          <Button color="dark" size="sm" onClick={toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
          <Button color="success" size="sm" onClick={saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default OrderCompletion;