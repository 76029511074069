/* eslint-disable no-unused-vars */
import { getUserDetails } from "./authentication";

export const getEditId = () => {
  if (window.localStorage.getItem("EditId")) {
    return Number(window.localStorage.getItem("EditId"));
  }
  else
    return 0;
}

export const setEditId = (data) => {
  try {
    window.localStorage.setItem("EditId", data);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export const getDefaultAddressId = () => {
  if (window.localStorage.getItem("DefaultAddressId")) {
    return Number(window.localStorage.getItem("DefaultAddressId"));
  }
  else
    return 0;
}

export const setDefaultAddressId = (data) => {
  try {
    window.localStorage.setItem("DefaultAddressId", data);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}


export const getIsAdmin = () => {
  if (window.localStorage.getItem("isAdmin")) {
    return window.localStorage.getItem("isAdmin");
  }
  else
    return 0;
}

export const setIsAdmin = (data) => {
  try {
    window.localStorage.setItem("isAdmin", data);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export const removeAuthToken = () => {
  if (window.localStorage.getItem("AuthToken") != null) {
    var data = JSON.parse(window.localStorage.removeItem("AuthToken"));
    return data.authToken;
  }
  else
    return null;

};

export const IsDevEnv = () => {
  const NODE_ENV = process.env.NODE_ENV || "development";

  return NODE_ENV === "development";
}

export const getCurrentDomain = () => {
  const NODE_ENV = process.env.NODE_ENV || "development"
  if (NODE_ENV === "development")
    return "https://triggercraft.co.za/";
  else
    //return "https://triggercraft.azurewebsites.net/";
    return "/";
}

export const CanAddActivity = () => {
  if (window.localStorage.getItem("IsAddingActivity"))
    return parseInt(window.localStorage.getItem("IsAddingActivity")) === 0;
  else
    return true;
}

export const setAddingActivity = (state) => {
  try {
    window.localStorage.setItem("IsAddingActivity", state);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export const GetCartData = () => {
  try {
    const FilteredData = window.localStorage.getItem("cart");
    if (FilteredData) {
      return JSON.parse(FilteredData);
    }
    return [];
  } catch (e) {
    return [];
  }
}

export const SetCartData = (data) => {
  try {
    //if (!data.userId && getUserDetails())
    //  data.userId = getUserDetails().id;

    window.localStorage.setItem("cart", JSON.stringify(data));
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}
