import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { Inject } from '@syncfusion/ej2-react-richtexteditor';
import { Query } from '@syncfusion/ej2-data';
import React, { Component } from 'react';
import { confirm } from "react-confirm-box";
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
//import '../../css/Select2.css';
import { getAuthToken } from '../../helpers/authentication';
import { Context } from '../../helpers/Context';
import { capitalizeObjectKeys } from '../../helpers/Global';
import { getCurrentDomain } from '../../helpers/Lookup';
//import Select2 from '../../helpers/Select2';
import { fieldRequired } from '../../helpers/validation';

export class AdminOrders extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    const commandTemplate = [
      /*{ type: 'Send To Processing', buttonOption: { cssClass: 'e-flat', iconCss: 'fas fa-paper-plane' } },*/
      { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat text-danger', iconCss: 'e-delete e-icons' } }
    ];

    const CartObject = {
      id: 0,
      userId: 0,
      cartId: "",
      orderType: 5,
      dateStarted: new Date(),
      dateSubmitted: null,
      dateComplete: null,
      numItems: 0,
      orderId: 0,
      amount: 0,
      deliveryFee: 0,
      vat: 0,
      promoCode: 0,
      useCredit: true,
      stockCheck: true,
      status: 0,
      deliveryAddressId: 0,
      instructions: "",
      statusMessage: "",
      paymentMessage: "",
      paymentMeta: "",
      deliveryMessage: "",
      deliveryMeta: "",
      cartItems: []
    }

    this.state = {
      editData: [], loading: true, editModal: false, addProductModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
      id: 0,
      Status: 0,
      UserId: 0,
      ProductQuantity: 1,
      CustomerOrders: [],
      CustomerCart: CartObject,
      ProductList: [],
      BrandList: [],
      CategoryList: [],
      SubCategoryList: [],
      fProductBrandId: 0,
      ProductId: 0,
      fSubcategoryId: [],
      couponCode: "",
      filteredProducts: []
    };
    this.toggle = this.toggle.bind(this);
    this.toggleAddProduct = this.toggleAddProduct.bind(this);

  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  toggleAddProduct() {
    this.setState({
      addProductModal: !this.state.addProductModal
    });
  }

  componentDidMount() {
    document.title = "Admin Orders :: TriggerCraft";
    this.loadData();
  }

  editItem = (id) => {
    const { setAdminAppliedCoupon, setAdminCouponError } = this.context;
    setAdminCouponError("");
    if (id > 0) {
      let data = this.state.editData.find((item) => { return item.id == id });
      data = { ...data, cartItems: data.cartItems.map(ci => ({ ...ci, title: ci.Title, quantity: ci.Qty, price: ci.PriceCharged, productId: ci.ProductId, product: this.state.ProductList.find(item => item.id == ci.ProductId) })) };
      this.setState({ id: data.id, cartId: data.cartId, CustomerCart: data, CustomerOrders: data, UserId: data.userId, amount: data.amount, numItems: data.numItems, couponCode: "" });
    }
    else {
      const CartObject = {
        id: 0,
        userId: 0,
        cartId: "",
        orderType: 5,
        dateStarted: new Date(),
        dateSubmitted: null,
        dateComplete: null,
        numItems: 0,
        orderId: 0,
        amount: 0,
        deliveryFee: 0,
        vat: 0,
        promoCode: 0,
        useCredit: true,
        stockCheck: true,
        status: 0,
        deliveryAddressId: 0,
        instructions: "",
        statusMessage: "",
        paymentMessage: "",
        paymentMeta: "",
        deliveryMessage: "",
        deliveryMeta: "",
        cartItems: []
      }
      //clear state fields
      this.setState({
        id: 0,
        SiteSection: 0,
        UserId: 0,
        CustomerOrders: [],
        CustomerCart: CartObject,
        couponCode: "",
        Status: 0,
        //SubCategoryList: [],
        //ProductBrandId: 0,
        //fSubcategoryId: [],
      });
    }
    this.setState({ editId: id, editModal: true });
  };

  reducePrice = (originalPrice, percentage) => {
    const decimalPercentage = percentage / 100;

    const reductionAmount = originalPrice * decimalPercentage;

    const newPrice = originalPrice - reductionAmount;

    return newPrice;
  }

  saveItem = async (event) => {
    const { adminPostToCart, adminAppliedCoupon, setAdminAppliedCoupon } = this.context;
    const { CustomerCart } = this.state;
    if (adminAppliedCoupon?.id && CustomerCart.promoCode == 0) {
      CustomerCart.promoCode = adminAppliedCoupon.id;
      CustomerCart.amount = adminAppliedCoupon.type == 1 ? (CustomerCart.amount - adminAppliedCoupon.amount) : this.reducePrice(CustomerCart.amount, adminAppliedCoupon.amount);
    }
    //console.log(CustomerCart)
    event.stopPropagation();
    var valid = true;
    valid &= this.state.CustomerOrders.cartItems.length > 0;
    valid &= fieldRequired(this.state.UserId, 'ddUserIdError', '* required');

    if (valid) {
      await adminPostToCart(CustomerCart);
      this.setState({ editModal: false, loading: true });
      setAdminAppliedCoupon(null);
      this.loadData();
    }
  }

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {
      case 'Edit':
        this.editItem(args.rowData.id);
        break;
      case 'Delete':
        this.deleteItem(args.rowData.id);
        break;
    }
  }

  handleFilterSelectChange(event) { const selectedValue = Array.from(event.target.selectedOptions).map(option => parseInt(option.value)); this.setState({ fSubcategoryId: selectedValue }); }

  handleFilterUnSelectChange(event) {
    this.setState({ fSubcategoryId: [] });
  }

  sendEmail(data) {
    const newData = data.cartItems.map(item => ({
      Id: item.Id,
      CartId: item.CartId,
      ProductId: item.ProductId,
      BuyType: 0,
      Qty: item.Qty,
      PriceCharged: item.PriceCharged,
      TotalCharged: item.TotalCharged,
      Status: 1,
      Title: item.Title,
      Sku: item.Sku
    }))
    data.orderData = JSON.stringify(newData)
    //console.log(capitalizeObjectKeys(data))
    this.saveData(capitalizeObjectKeys(data));
    this.setState({ editModal: false });
  }

  handleCouponChange = (code) => {

    this.setState({ couponCode: code })
  };

  addToCart() {
    const { CustomerCart, UserId, ProductId, ProductQuantity, ProductList } = this.state;
    const product = ProductList.find(item => item.id == ProductId);
    const currentPrice = (product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date()) || product.price;
    const existingItem = CustomerCart?.cartItems.find(item => item.productId === ProductId);

    if (existingItem) {
      existingItem.quantity += ProductQuantity;
    } else {
      const newItem = {
        cartId: 0,
        id: 0,
        img: product.images,
        price: currentPrice,
        product: { ...product },
        productId: ProductId,
        quantity: ProductQuantity
      };

      CustomerCart.cartItems.push(newItem);
    }

    const updatedCart = {
      ...CustomerCart,
      numItems: CustomerCart.cartItems.reduce((total, item) => total + item.quantity, 0),
      amount: CustomerCart.cartItems.reduce((total, item) => total + (item.price * item.quantity), 0),
      userId: UserId
    };

    if (updatedCart.promoCode) updatedCart.amount = updatedCart.amount - updatedCart.promoCode;

    this.setState({ CustomerCart: updatedCart, ProductId: 0, ProductQuantity: 1, fProductBrandId: 0, fSubcategoryId: [], CustomerOrders: updatedCart, addProductModal: false });
  }

  async removeFromCart(productId, database, data, removeId = 0) {
    const { deleteFromCart } = this.context;
    const { CustomerCart } = this.state;

    const updatedCartItems = CustomerCart.cartItems.filter(item => item.productId !== productId);

    const updatedCart = {
      ...CustomerCart,
      cartItems: updatedCartItems,
      numItems: updatedCartItems.reduce((total, item) => total + item.quantity, 0),
      amount: updatedCartItems.reduce((total, item) => total + (item.price * item.quantity), 0),
    };

    if (updatedCart.promoCode) updatedCart.amount = updatedCart.amount - updatedCart.promoCode;

    if (database) await deleteFromCart(data, database, removeId)

    this.setState({ CustomerCart: updatedCart, CustomerOrders: updatedCart });
  }

  static renderDataTable(data, gridCommands, gridToolbar, commandClick) {
    //headerTextAlign="right" textAlign="right" template={(props) => <>{currencySymbol + " " + props.CatastrophicLevel.toNumberString()}</>}
    return (
      <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} toolbar={gridToolbar} >
        <ColumnsDirective>
          <ColumnDirective field='emailAddress' width='100' headerText="Email" />
          <ColumnDirective field='FullName' width='100' headerText="Customer Name" />
          <ColumnDirective field='numItems' width='50' headerText="Num. Items" headerTextAlign="center" textAlign="center" />
          <ColumnDirective field='date' width='50' headerText="Created" type="datetime" format="yyyy-MM-dd" />
          <ColumnDirective field='amount' width='50' headerText="Total" headerTextAlign="right" textAlign="right" template={(props) => <>{"R " + props.amount.toNumberString()}</>} />
          <ColumnDirective headerText='Actions' width='80' commands={gridCommands} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      </GridComponent>
    );
  }

  renderProductsTable(data) {
    //const { handleNavigate, deleteFromCart } = this.context;

    return (
      <>
        {data.cartItems?.length ? data.cartItems.map((item, index) => {
          let imageUrl = "/assets/imgs/Trigger-Craft-Logo.png";

          if (item.img || item.product.images) {
            let images = item.img || item.product.images;

            if (item.product.images.includes("DynamicData") || item.img.includes("DynamicData")) images = JSON.parse(item.product.images) || JSON.parse(item.img);

            if (Array.isArray(images)) {
              images.forEach((pic) => {
                if (pic.includes("DynamicData") && pic.includes("[")) {
                  const parsedImage = JSON.parse(pic);
                  imageUrl = getCurrentDomain() + parsedImage[0];
                } else {
                  imageUrl = getCurrentDomain() + pic;
                }
              });
            } else if (images.includes("https") && images.includes("[")) {
              imageUrl = JSON.parse(images.split(", ")[0])[0];
            } else {
              imageUrl = images.split(", ")[0];
            }
          }

          return (
            <div key={index} className="wishlist-item">
              <i className="fas fa-times mx-2 xmark p-2" onClick={() => { item.Id ? this.removeFromCart(item.productId, true, data, item.Id) : this.removeFromCart(item.productId, false, data) }}></i>
              <div className="d-flex align-items-center col-md product" style={{ cursor: "pointer" }}>
                <div className="image-container">
                  <img src={imageUrl} alt="Product image" />
                </div>
                <h3>{item.product.title}</h3>
              </div>
              <p className="col-md-2">R{item.price}.00</p>
              <p className={item.product.stock > 0 ? "text-success col-md-2" : "text-danger col-md-2"}>{item.product.stock > 0 ? "In Stock" : "Out Of Stock"}</p>
              <p className="col-md-2">Qty: {item.quantity}</p>
            </div>
          );
        }) :
          <div className="text-center empty-wish my-3">
            <i className="far fa-shopping-cart fa-4x text-muted"></i>
            <p>No Items In Cart</p>
          </div>
        }
      </>
    );
  }

  onFilteringUser(args) {
    let filter = this.state.UserList.filter(p => p.FullName.toLowerCase().indexOf(args.text.toLowerCase()) > 0)
    args.updateData(filter);
  }

  onFilteringProduct(args) {
    let data = this.state.ProductList;
    if (this.state.fProductBrandId !== 0) {
      data = data?.filter(item => item.productBrandId === this.state.fProductBrandId || (!this.state.fProductBrandId && !item.productBrandId));
    }
    let filter = data?.filter(p => p.filterName.toLowerCase().indexOf(args.text.toLowerCase()) > 0)
    args.updateData(filter);
  }


  render() {
    const { handleNavigate, applyAdminCoupon, adminCouponError, adminAppliedCoupon } = this.context;

    let data = this.state.ProductList;
    let quantity = this.state.ProductQuantity;
    if (quantity < 1) quantity = 1;

    if (this.state.fProductBrandId !== 0) {
      data = data?.filter(item => item.productBrandId === this.state.fProductBrandId || (!this.state.fProductBrandId && !item.productBrandId));
    }
    if (this.state.fSubcategoryId.length) {
      data = data?.filter(item => item.selectedCategories && JSON.parse(item.selectedCategories) && JSON.parse(item.selectedCategories).some(categoryId => this.state.fSubcategoryId.includes(categoryId)));
    }
    //this.setState({ filteredProducts: data })
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : AdminOrders.renderDataTable(this.state.editData, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);
    let mapedData = this.state.CategoryList.map((item, index) => { return { text: item.title, children: this.state.SubCategoryList.filter(sub => sub.categoryId === item.id).map(sub => { return { text: sub.title, id: sub.id } }) } })
    //console.log(this.state, adminAppliedCoupon);
    return (
      <>
        <div className="container">
          <Row>
            <Col xs={6}>
              <h1>Admin Orders</h1>
            </Col>
            <Col xs={6} className="text-end align-self-center">
              <Button className="mx-2" color="dark" outline size="sm" data-recordid="0" onClick={e => handleNavigate(-1)}><i className="fas fa-chevron-left mr-2"></i> Back To Admin</Button>
              <Button color="primary" size="sm" data-recordid="0" onClick={() => this.editItem(0)}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {contents}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} size="lg" scrollable backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit Admin Order</ModalHeader>
          <ModalBody>
            <div className='row'>
              <div className='col-md-9 mb-3'>
                {!this.state.id ?
                  <><DropDownListComponent id='ddUserId' name='ddUserId' placeholder='Customer' filtering={this.onFilteringUser = this.onFilteringUser.bind(this)} allowFiltering={true} showClearButton={true} dataSource={this.state.UserList} fields={{ text: 'FullName', value: 'id' }} floatLabelType='Always' value={this.state.UserId} change={e => this.setState({ UserId: e.itemData.id })} />
                    <div id='ddUserIdError' className='error-message' /></>
                  :
                  <><TextBoxComponent disabled={this.state.id} id='ddUserId' name='ddUserId' placeholder='Customer' floatLabelType='Always' value={this.state.CustomerCart.FullName} /> <div id='ddUserIdError' className='error-message' /></>
                }
              </div>
              {this.state.UserId ? <div className='col-md-3 mb-3 text-end align-self-end'>
                <Button style={{ background: "#74bc1f", color: "#fff" }} size="sm" onClick={this.toggleAddProduct}>Add Product <i className="fas fa-plus-circle ms-2"></i></Button>
              </div> : ""}
            </div>

            {this.renderProductsTable(this.state.CustomerOrders)}
            <hr />
            {!this.state.CustomerOrders.promoCode ? <div className="coupon-button col-12">
              <div className="input-group mb-3">
                <input className="coupon-input" type="text" placeholder="Enter coupon code" value={this.state.couponCode} onChange={(e) => this.handleCouponChange(e.target.value)} />
                <button disabled={adminAppliedCoupon?.id} className="coupon-button" onClick={() => applyAdminCoupon(this.state.couponCode, this.state.UserId)}>Apply</button>
              </div>
              {adminCouponError && <p className="coupon-error">{adminCouponError}</p>}
              {adminAppliedCoupon?.id ? (
                <div>
                  <p className="coupon-message">Coupon applied: {adminAppliedCoupon.code}</p>
                </div>
              ) : ""}
            </div> : ""}
          </ModalBody>
          <ModalFooter className="justify-content-start">
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button style={{ background: "#74bc1f", color: "#fff" }} onClick={e => this.saveItem(e)} size="sm">Save order <i className="far fa-check-circle ms-2"></i></Button>
            {this.state.id && this.state.CustomerOrders.orderData && JSON.parse(this.state.CustomerOrders.orderData).length === this.state.CustomerOrders.cartItems.length ? <Button color="info" size="sm" onClick={() => this.sendEmail(this.state.CustomerOrders)}>Send Customer Notification <i className="far fa-times-circle ms-2"></i></Button> : ""}
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.addProductModal} toggle={this.toggleAddProduct} size="lg" scrollable backdrop="static">
          <ModalHeader toggle={this.toggleAddProduct} close={<button className="close" onClick={this.toggleAddProduct}><i className="fas fa-times"></i></button>}>Add Product</ModalHeader>
          <ModalBody>
            <div className='row'>
              <div className='col-md-12 mb-3'>
                <DropDownListComponent id='ddProductBrandId' name='ddProductBrandId' allowFiltering={true} placeholder='Brand' dataSource={[{ title: "All", id: 0 }, ...this.state.BrandList]} fields={{ text: 'title', value: 'id' }} floatLabelType='Always' value={this.state.fProductBrandId} change={e => this.setState({ fProductBrandId: e.itemData ? e.itemData.id : 0, ProductId: 0 })} />
              </div>
              <div className='col-md-6 mb-3 d-none'>
                <Label className="mb-0 text-dark">Subcategories</Label>
                {/*<Select2 className='form-control form-control-sm' multiple defaultValue={this.state.fSubcategoryId} data={mapedData} onSelect={(e) => this.handleFilterSelectChange(e)} onUnselect={() => this.handleFilterUnSelectChange()} />*/}
              </div>
              {this.state.fProductBrandId > 0 || this.state.fSubcategoryId.length ? <>
                <div className='col-md-12 mb-3'>
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td>
                          <DropDownListComponent id='ddProducts' name='ddProducts' filtering={this.onFilteringProduct = this.onFilteringProduct.bind(this)} allowFiltering={true} placeholder='Product' dataSource={[{ filterName: "-Select-", id: 0 }, ...data]} fields={{ text: 'filterName', value: 'id' }} floatLabelType='Always' value={this.state.ProductId} change={e => this.setState({ ProductId: e.itemData ? e.itemData.id : 0 })} />
                        </td>
                        <td style={{ width: "110px" }}>
                          <label className="ms-2">QTY</label>
                          <div className="admin-quantity-buttons">
                            <span onClick={() => this.setState({ ProductQuantity: this.state.ProductQuantity -= 1 })}>-</span>
                            <span>{quantity}</span>
                            <span onClick={() => this.setState({ ProductQuantity: this.state.ProductQuantity += 1 })}>+</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>


                </div>

              </> : ""}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggleAddProduct}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button style={{ background: "#74bc1f", color: "#fff" }} onClick={() => this.addToCart()} size="sm">Add <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
      </>

    );
  }

  async loadData() {
    const { getProducts } = this.context;
    var bearer = 'Bearer ' + getAuthToken();

    let loadCnt = 5

    //get brands
    try {
      const response = await fetch('api/productbrands', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ BrandList: data });
        loadCnt--;
        if (loadCnt === 0)
          this.setState({ loading: false });
        //console.log("Product Brands", loadCnt, data);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }

    //get prod sub categories
    try {
      const response = await fetch('api/productsubcategories', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ SubCategoryList: data });
        loadCnt--;
        if (loadCnt === 0)
          this.setState({ loading: false });
        //console.log("Sub  Categories", loadCnt, data);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }

    //get prod categories
    try {
      const response = await fetch('api/productcategories', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ CategoryList: data });
        loadCnt--;
        if (loadCnt === 0)
          this.setState({ loading: false });

        //console.log("Product Categories", loadCnt, data);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }

    //get customers
    try {
      const response = await fetch('api/userdata/getcustomers', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        data = data?.map(item => ({ ...item, FullName: `${item.fullName} (${item.emailAddress})` }));
        //console.log(data)
        this.setState({ UserList: data });
        loadCnt--;
        if (loadCnt === 0)
          this.setState({ loading: false });
        //console.log("Customer", loadCnt, data);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }

    //get admin orders
    try {
      const response = await fetch('api/cart/getadminorders', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        //console.log(data);
        data = data.map(item => ({ ...item, date: new Date(item.dateStarted).toDateString(), FullName: `${item.firstName} ${item.lastName}`, cartItems: JSON.parse(item.orderData) })).filter(item => item.status === 0);
        //data = data.filter(item => item.status !== 0)
        this.setState({ editData: data, ProductList: await getProducts() });

        loadCnt--;
        if (loadCnt === 0)
          this.setState({ loading: false });
        //console.log("Admin Orders", loadCnt, data);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {

      console.error(e);
    }
  }

  async saveData(data) {

    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
    //var data = { Id: this.state.Id, Id: this.state.Id, SiteSection: this.state.SiteSection, Title: this.state.Title, Filename: Array.isArray(this.state.Filename) ? this.state.Filename.join(", ") : this.state.Filename, StartDate: this.state.StartDate, EndDate: this.state.EndDate, ActionType: this.state.ActionType, ActionData: this.state.ActionData, DisplayOrder: this.state.DisplayOrder, AddedBy: this.state.AddedBy, DateAdded: this.state.DateAdded, ModifiedBy: this.state.ModifiedBy, DateModified: this.state.DateModified, Status: this.state.Status, }

    try {
      const response = await fetch('api/cart/sendadminemail', {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        //await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Customer Email', content: 'The email was successfully sent to the customer!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
        else {
          this.setState({ loading: false });
          ToastUtility.show({
            title: 'Customer Email', content: 'There was an error sending the email to the customer!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
          });
        }
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Customer Email', content: 'There was an error sending the email to the customer!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/cart/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Cart', content: 'The cart was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Cart', content: 'There was an error deleting the cart!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}

