import React, { Component } from 'react';
import { GoogleApiProvider } from 'react-gapi';
import { Route, Routes } from 'react-router-dom';
import AppRoutes, { AdminRoutes } from './AppRoutes';
import { BrandLayout } from './components/BrandLayout';
import BrandSpecificPage from './components/BrandSpecificPage';
import { Layout } from './components/Layout';
import ProtectedRoute from './components/ProtectedRoutes';
import RazerCategoryPage from './components/RazerCategoryPage';
import RazerProductView from './components/RazerProductView';
import './css/app.css';
import './helpers/NumberExtension';
import './helpers/string-prototypes';


export default class App extends Component {
  static displayName = App.name;

  render() {
    //const isLoggedIn = getAuthToken();

    return (
      <GoogleApiProvider clientId='986281077299-plkqehrf7otvbhaad3o5713mlaje9jdb.apps.googleusercontent.com'>
        <Routes>
          <Route
            path="/*"
            element={
              <Layout>
                {AppRoutes.map((route, index) => {
                  const { element, ...rest } = route;
                  return <Routes key={index}><Route {...rest} element={element} /></Routes>;
                })}
              </Layout>
            }
          />
          <Route
            path="/admin/*"
            element={
              <Layout>
                {AdminRoutes.map((route, index) => {
                  const { element, ...rest } = route;
                  return <ProtectedRoute key={index} {...rest} element={element} />;
                })}
              </Layout>
            }
          />
          <Route path="/brands/:slug" element={<BrandLayout><BrandSpecificPage /></BrandLayout>} />
          <Route path="/categories/razer/:category" element={<BrandLayout><RazerCategoryPage /></BrandLayout>} />
          <Route path="/products/razer-product-view/:sku" element={<BrandLayout><RazerProductView /></BrandLayout>} />
        </Routes>
      </GoogleApiProvider>
    );
  }
}
