import React, { useContext } from "react";
import { Context } from "../helpers/Context";
import { getCurrentDomain } from "../helpers/Lookup";

const CartItem = () => {
  const { cartItems, handleRemoveFromCart, handleCartProductQuantity } = useContext(Context);
  const getImage = (images) => {
    let image = "/assets/imgs/Trigger-Craft-Logo.png";
    if (images) {
      if (images.includes("DynamicData")) images = JSON.parse(images);

      if (Array.isArray(images)) {
        images.map((pic) => {
          if (pic.includes("DynamicData") && pic.includes("[")) {
            const parsedImage = JSON.parse(pic);
            image = getCurrentDomain() + parsedImage[0];
          } else {
            image = getCurrentDomain() + pic;
          }
        });
      } else if (images.includes("https") && images.includes("[")) {
        image = JSON.parse(images.split(", ")[0])[0];
      } else {
        image = images.split(", ")[0];
      }
    }
    return image;
  }
  //console.log("Cart: ", cartItems)


  return (
    <div className="cart-products">
      {cartItems?.filter(p => p.product).map((item) => (
        
        <div className="search-result-item" key={item.productId} >
          <div className="image-container">
            <img src={getImage(item.product?.images)} alt="product image" />
          </div>
          <div className="prod-details">
            <span className="name">{item.product.title}</span>
            <i className="fas fa-xmark ms-2" onClick={() => handleRemoveFromCart(item)}>
            </i>
            <div className="quantity-buttons">
              <span onClick={() => handleCartProductQuantity("dec", item)} >
                -
              </span>
              <span>{(item.quantity <= item.product.stock) ? item.quantity : item.product.stock}</span>
              <span onClick={() => handleCartProductQuantity("inc", item)}>
                +
              </span>
            </div>
            <div className="text">
              <span>{item.quantity}</span>
              <span>x</span>
              <span className="highlight">
                {(item.price * item.quantity).toCurrencyString()}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CartItem;